import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const HaberlerPage = ({ data, location }) => {
    const posts = data.allMarkdownRemark.nodes
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (
        <Layout>
            <GatsbySeo
                title="Basından Haberler"
                description="Kâzım Yardımcı hakkında basında yayınlanmış haberler."
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: "Basından Haberler",
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Basından Haberler</h1>
                    <span>Kazım Yardımcı hakkında medyada yer alan haberler</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container">
                        <div className="row gutter-50">
                            <div className="col-lg-12 mt-lg-0">

                                <div className="row col-mb-50 posts-md">
                                    {posts.filter(value => value.frontmatter.isPress).map(post => {

                                        return (
                                            <div className="col-md-12 pb-4" key={post.frontmatter.slug}>
                                                <article className="entry">
                                                    <div className="entry-title title-sm">
                                                        <h3><Link to={`${post.frontmatter.slug}`} className="color-underline stretched-link" >{post.frontmatter.title}</Link></h3>
                                                    </div>
                                                    <div className="entry-meta">
                                                        <ul>
                                                            <li>{post.frontmatter.date}</li>
                                                            {post.frontmatter.author && <li>{post.frontmatter.author}</li>}
                                                            {post.frontmatter.source && <li>{post.frontmatter.source}</li>}
                                                        </ul>
                                                    </div>

                                                    <div className="entry-content mt-3">
                                                        <p>{post.excerpt}</p>
                                                    </div>
                                                </article>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Sevenlerin Dilinden</h1>
                    <span>Sevenlerin dilinden Kazım Yardımcı</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container">

                        <div className="row gutter-50">
                            <div className="col-lg-12 mt-lg-0">

                                <div className="row col-mb-50 posts-md">
                                    {posts.filter(value => !value.frontmatter.isPress).map(post => {

                                        return (
                                            <div className="col-md-12 pb-4" key={post.frontmatter.slug}>
                                                <article className="entry">
                                                    <div className="entry-title title-sm">
                                                        <h3><Link to={`${post.frontmatter.slug}`} className="color-underline stretched-link" >{post.frontmatter.title}</Link></h3>
                                                    </div>
                                                    <div className="entry-meta">
                                                        <ul>
                                                            <li>{post.frontmatter.date}</li>
                                                            {post.frontmatter.author && <li>{post.frontmatter.author}</li>}
                                                            {post.frontmatter.source && <li>{post.frontmatter.source}</li>}
                                                        </ul>
                                                    </div>

                                                    <div className="entry-content mt-3">
                                                        <p>{post.excerpt}</p>
                                                    </div>
                                                </article>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default HaberlerPage

export const pageQuery = graphql`
  query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/content/basindan-haberler/"  }}
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          source
          author
          slug
          no
          isPress
        }
      }
    }
  }
`
